/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-19 01:03:00
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-19 01:23:08
 * @FilePath: /webmanagepark/src/icons/index.js
 * @Description:
 */
import SvgIcon from '@/components/SvgIcon/IconIndex.vue'

const svgRequired = require.context('./svg', false, /\.svg$/)
svgRequired.keys().forEach((item) => svgRequired(item))

export default (app) => {
  app.component('svg-icon', SvgIcon)
}
