import Contextmenu from '@/views/headers/ContextMenu.vue'

const commons = [
  {
    name: 'contextmenu',
    component: Contextmenu,
    register: () => {
      // 创建根节点
      const rootNode = document.createElement('div')
      rootNode.id = 'contextmenu'
      document.body.appendChild(rootNode)
    }
  }
]

const GlobalCommon = {
  install: (app) => {
    commons.forEach((common) => {
      app.component(common.name, common.component)
      common.register && common.register()
    })
  }
}

export default GlobalCommon
