/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-15 00:09:43
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-27 14:34:38
 * @FilePath: /WebManagePark/src/store/index.js
 * @Description:
 */
import { apiGetLogin, apiGetUserMenu } from '@/apis/login'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '../router/index'
import { getAsyncRoutes } from '@/router/asyncRouter'
import { createStore } from 'vuex'
import { setTokenTime } from '@/utils/auth'
const OpenMessageBox = (message) => {
  ElMessageBox.confirm(
    message,
    '警告',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelButton: false,
      type: 'warning'
    }
  )
    .then(() => {

    })
    .catch(() => {
    })
}
const clearCache = (state) => {
  let usertab = state.usertab
  if (usertab === null || usertab === undefined) usertab = []
  state.cachedList = usertab.map((view) => {
      return view.name
  }
  )
}
const store = createStore({
  state: {
    token: localStorage.getItem('token') || '',
    userName: localStorage.getItem('userName') || '',
    userMenu: sessionStorage.getItem('userMenu') || [],
    siderType: true,
    userimage: localStorage.getItem('userImage') || '',
    usertab: [],
    activeTab: '',
    cachedList: []
  },
  mutations: {
     setTab_AddCached(state, routeName) {
       const name = routeName.split('/')
       if (name.length > 0) { routeName = name[name.length - 1] }
       // 判断路由是否已经存在
      const isViewExist = state.cachedList.some((viewName) => viewName === routeName)
      // 路由不存在则将路由加入cachedList
      if (!isViewExist) {
        state.cachedList.push(routeName)
      }
    },
    setTab_RemoveCached(state, routeName) {
       const name = routeName.split('/')
       if (name.length > 0) { routeName = name[name.length - 1] }
      // 判断路由是否存在
      const isViewExist = state.cachedList.some((viewName) => viewName === routeName)
      if (!isViewExist) {
        return
      }
      // 存在就删除
      const viewIndex = state.cachedList.indexOf(routeName)
      state.cachedList.splice(viewIndex, 1)
    },
    setTab_Init(state) {
      // sessionStorage.setItem('usertab', [])
    },
    setTab_Add(state, tab) {
      let usertab = state.usertab
      if (usertab === null || usertab === undefined) usertab = []
      const uTab = usertab.filter((item) => {
          return item.address === tab.address
        })
      if (uTab.length <= 0) {
        usertab.push(tab)
        state.usertab = usertab
      }
      state.activeTab = tab.name
    },
    setTab_Remove(state, tab) {
      const userTab = state.usertab
      const index = userTab.findIndex((item) => {
          return item.name === tab
      })
      if (index >= 0) {
          userTab.splice(index, 1)
          state.usertab = userTab
          if (state.activeTab === tab) {
              if (index >= userTab.length) {
                // 如果该路由的索引值不小于删除后的viewList长度，则将激活路由设置为viewList中最后一个路由
                state.activeTab = userTab[userTab.length - 1].name
              } else {
                // 反之，仍将激活路由设置为原索引值对应的路由
                state.activeTab = userTab[index].name
              }
          }
          clearCache(state)
          router.push({ name: state.activeTab })
      }
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    setUserName(state, username) {
      state.userName = username
      localStorage.setItem('userName', username)
    },
    setUserImage(state, userimage) {
      state.userimage = userimage
      localStorage.setItem('userImage', userimage)
    },
    setUserMenu(state, userMenu) {
      state.userMenu = userMenu
      sessionStorage.setItem('userMenu', userMenu)
    },
    changeSiderType(state) {
      state.siderType = !state.siderType
    },
    addRouter(state) {
      // console.log('state.userMenu', state.userMenu)
      const menuJson = JSON.parse(state.userMenu)
      // console.log('menuJson', menuJson)
      getAsyncRoutes(menuJson)
      // // console.log('accessRoutes')
      // // console.log(router.getRoutes(), '查看现有路由')
    }
  },
  actions: {
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        apiGetLogin(userInfo)
          .then((res) => {
            if (res.errcode === 0) {
              const menu = res.content.toString().replace(/\\"/g, '"')
              const name = res.username
              const token = res.token
              const image = res.userimage
              const simplemessage = res.simplemessage
              const simplepwd = res.simplepwd
              setTokenTime()
              commit('setTab_Init')
              commit('setToken', token)
              commit('setUserName', name)
              commit('setUserMenu', menu)
              commit('setUserImage', image)
              commit('addRouter')
              router.replace('/home')
              if (simplepwd === 1) {
                OpenMessageBox(simplemessage)
              }
            } else {
              ElMessage.error(res.msg)
            }
          })
          .catch(function (err) {
            // console.log(err)
            reject(err)
          })
      })
    },
    logout({ commit }) {
      try {
        commit('setToken', '')
        commit('setUserName', '')
        commit('setUserMenu', [])
        commit('setUserImage', '')
        commit('cachedList', [])
        commit('usertab', [])
        commit('activeTab', '')
        localStorage.clear()
        router.replace('/login')
        // router.push({ name: 'login' })
        router.go(0)
      } catch (e) { console.log('logout', e) }
    },
    setRouter({ commit }) {
      commit('addRouter')
    },
    setMenu({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        apiGetUserMenu(userInfo)
          .then((res) => {
            if (res.errcode === 0) {
              const menu = res.content.toString().replace(/\\"/g, '"')
              // console.log('重新获取菜单')
              commit('setUserMenu', menu)
              commit('addRouter')
              router.replace('/home')
            } else {
              ElMessage.error(res.msg)
            }
          })
          .catch(function (err) {
            // console.log(err)
            reject(err)
          })
      })
    },
    setTab_Add({ commit }, tab) {
      commit('setTab_Add', tab)
    },
    setTab_Remove({ commit }, tab) {
      commit('setTab_Remove', tab)
    },
    setTab_AddCached({ commit }, tab) {
      commit('setTab_AddCached', tab)
    },
    setTab_RemoveCached({ commit }, tab) {
      commit('setTab_RemoveCached', tab)
    }
  }
})
export default store
