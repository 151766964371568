/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-19 01:34:08
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-19 20:53:20
 * @FilePath: /webmanagepark/src/apis/login.js
 * @Description:
 */
import httpRequest from '../request/index'

export function apiGetLogin(param) {
  return httpRequest({
    url: 'SystemLogin',
    method: 'post',
    data: param
  })
}
export function apiGetUserMenu(param) {
  return httpRequest({
    url: 'SystemMenu',
    method: 'post',
    data: param
  })
}
