<!--
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-19 00:54:32
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-19 02:15:30
 * @FilePath: /webmanagepark/src/components/SvgIcon/IconIndex.vue
 * @Description:
-->
<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  icon: {
    type: String,
    required: true
  }
})
const iconName = computed(() => {
  return `#icon-${props.icon}`
})
</script>

<style lang="scss" scoped>
.svg-icon{
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
