/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-19 01:35:06
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-20 23:54:09
 * @FilePath: /webmanagepark/src/utils/auth.js
 * @Description:
 */
import { TOKEN_TIME, TOKEN_TIME_VALUE } from './constant'

export const setTokenTime = () => {
  localStorage.setItem(TOKEN_TIME, Date.now())
}
export const getTokenTime = () => {
  return localStorage.getItem(TOKEN_TIME)
}
export const diffTokenTime = () => {
  const currentTime = Date.now()
  const tokenTime = getTokenTime()
  return currentTime - tokenTime > TOKEN_TIME_VALUE
}
