/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-18 10:49:45
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-27 14:19:21
 * @FilePath: /webmanagepark/src/router/index.js
 * @Description:
 */
import { createRouter, createWebHashHistory } from 'vue-router'
// const ProjectType = process.env.VUE_APP_TYPE
// console.log('ProjectType', ProjectType)
const LoginPage = process.env.VUE_APP_LOGINPAGE
// console.log('LoginPage', LoginPage)
const routes = [
  {
    path: '/',
    redirect: { path: '/home' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(`@/views/login/${LoginPage}`)
  },
  {
    path: '/loginAuto',
    name: 'loginAuto',
    component: () => import('@/views/login/LoginAuto.vue')
  },
  {
    path: '/home',
    name: '主页',
    component: () => import('@/views/HomeIndex.vue'),
    children: []
  },
  {
    path: '/Report',
    name: '举报',
    component: () => import('@/views/login/Report.vue'),
    children: []
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
