/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-19 01:35:29
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-19 01:35:50
 * @FilePath: /webmanagepark/src/utils/constant.js
 * @Description:
 */

export const TOKEN_TIME = 'tonkenTime'

export const TOKEN_TIME_VALUE = 60 * 1000 * 60 * 12
