/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-19 15:39:57
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-19 19:02:10
 * @FilePath: /webmanagepark/src/router/asyncRouter.js
 * @Description:
 */
import router from '../router/index'
export function getAsyncRoutes(routes) {
  routes.forEach((item) => {
    if (item.address !== '') {
      router.addRoute('主页', {
        path: item.address,
        name: item.name,
        component: () => import(`@/views${item.address}`),
        meta: { label: item.label, keepAlive: true, fullpath: item.fullpath }
      })
    }
    if (item.submenu && item.submenu.length) {
      getAsyncRoutes(item.submenu)
    }
  })
}
