/*
 * @Author: 张旭 zhangxux@msn.com
 * @Date: 2022-05-14 13:45:35
 * @LastEditors: 张旭 zhangxux@msn.com
 * @LastEditTime: 2022-05-24 13:11:25
 * @FilePath: /WebManagePark/src/request/index.js
 * @Description:
 */
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { diffTokenTime } from '@/utils/auth'
import store from '../store'
import router from '@/router/index'
const whiteList = ['/login', '/loginAuto', '/Report']
const service = axios.create({
  baseURL: '/api', // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: false, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    'Content-Type': 'application/json',
    token: '',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // console.log('request', config)
    const path = router.currentRoute.value.fullPath
    if (localStorage.getItem('token')) {
      if (!diffTokenTime()) {
        config.headers.Authorization = localStorage.getItem('token')
        config.headers.Path = path
        // console.log('config1', config)
        return config
      }
    } else {
      if (whiteList.includes(path)) {
        return config
      }
    }
    store.dispatch('logout')
    return Promise.reject(new Error('token 失效'))
  },
  function (error) {
    // 对请求错误做些什么
    // console.log('111:' + error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // console.log('response')
    // console.log(response)
    const { data, status } = response
    if (status === 200 || status === 201) {
      if (data.errcode === 0) {
        return data
      } else {
        ElMessage.error(data.msg)
        return Promise.reject(new Error(data.msg))
      }
    } else {
      ElMessage.error(data.msg)
      return Promise.reject(new Error(data.msg))
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    error.response && ElMessage.error(error.response.data)
    // console.log(error.response)
    return Promise.reject(error.response.data)
  }
)

export default service
